import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { basePath } from 'actions/utils';
import { useDispatch } from 'react-redux';
import { setIsLoading } from 'actions/loader';
import * as userTypes from 'actions/users/types';
import setSecureCookie from 'utils/secure_cookie';
import { setError } from 'actions/registration';
import moment from 'moment';
import Cookies from 'universal-cookie';
import * as amplitude from '@amplitude/analytics-browser';

const cookies = new Cookies();

const getDomain = (url, subdomain) => {
	subdomain = subdomain || false;

	url = url.replace(/(https?:\/\/)?(www.)?/i, '');

	if (!subdomain) {
		url = url.split('.');

		url = url.slice(url.length - 2).join('.');
	} else {
		let arr = url.split('.');
		if (arr.length == 4) {
			url = arr.slice(arr.length - 3).join('.');
		}
	}

	if (url.indexOf('/') !== -1) {
		return url.split('/')[0];
	}

	return url;
};

/*
interface useUserRegistrationMutationProps {}
*/

const identifyEvent = new amplitude.Identify();

const useUserRegistrationMutation = (
	{ onError, onSuccess, setErrorInRedux } = {} /*:useUserRegistrationMutationProps*/
) => {
	const dispatch = useDispatch();
	// const queryClient = useQueryClient();

	const mutation = useMutation({
		mutationFn: user => {
			return axios.post(`${basePath()}/users`, JSON.stringify(user));
		},
		onMutate: async variables => {
			dispatch(setIsLoading(true, 'Creating User...'));

			return { variables };
		},
		onError: (error /*, variables, context*/) => {
			// An error happened!
			dispatch(setIsLoading(false));

			setErrorInRedux && setErrorInRedux(error);

			onError && onError(error);
			dispatch(setError(error.response.status));
		},
		onSuccess: (response /*variables, context*/) => {
			dispatch({ type: userTypes.AUTH_USER, payload: response.data.user.tokens });
			setSecureCookie({
				name: 'token',
				data: response.data.user.tokens.access,
				path: '/',
				domain: getDomain(window.location.hostname, true)
			});
			setSecureCookie({
				name: 'refresh',
				data: response.data.user.tokens.refresh,
				path: '/',
				domain: getDomain(window.location.hostname, true)
			});
			setSecureCookie({
				name: 'idToken',
				data: response.data.user.tokens.id,
				path: '/',
				domain: getDomain(window.location.hostname, true)
			});
			cookies.set('startTime', moment());
			amplitude.setUserId(response.data.user.id);
			identifyEvent.append('name', response.data.user.name.first + ' ' + response.data.user.name.last);
			identifyEvent.append('email', response.data.user.email.primary);
			amplitude.identify(identifyEvent);

			window.analytics &&
				window.analytics.identify(response.data.user.id, {
					name: response.data.user.name.first + ' ' + response.data.user.name.last,
					email: response.data.user.email.primary
				});

			dispatch(setIsLoading(false));
			if (response.data.errors) {
				dispatch(setError(response.data.response.code));

				onError && onError(response);
			} else {
				onSuccess && onSuccess(response.data);
			}
		}
	});

	return {
		isMutating: mutation.isPending,
		isError: mutation.isError,
		isSuccess: mutation.isSuccess,
		reset: mutation.reset,
		registerUser: mutation.mutate
	};
};

export default useUserRegistrationMutation;

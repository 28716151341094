import { useCallback, useMemo } from 'react';
import { hasProp } from 'utils/object';
import { getLowerCasedParams } from 'utils/url';
import useGeneralContext from 'context/GeneralContext';
import { useUserRegistrationMutation } from 'reactQuery/mutations';
import * as amplitude from '@amplitude/analytics-browser';

// interface useRegistrationFormFieldsProps {}
const identifyEvent = new amplitude.Identify();
const useRegistrationFormFields = ({ doAfterSubmit } /*:useRegistrationFormFieldsProps*/) => {
	const completeRegistrationStepOne = useCallback(
		res => {
			if (hasProp(res, 'response.code') && res.response.code === 200) {
				amplitude.setUserId(res.response.data.user.id);
				identifyEvent.append(
					'name',
					res.response.data.user.name.first + ' ' + res.response.data.user.name.last
				);
				identifyEvent.append('email', res.response.data.user.email.primary);
				identifyEvent.append(
					'companyName',
					hasProp(res.response.data.user, 'company') ? res.response.data.user.company.name : null
				);
				identifyEvent.append(
					'companyCode',
					hasProp(res.response.data.user, 'company') ? res.response.data.user.company.code : null
				);
				identifyEvent.append(
					'big_prize_campaign_code',
					res.response.data.user.bigPrizeCampaignCode ? true : false
				);
				identifyEvent.append(
					'highValueDiner',
					hasProp(res.response.data.user, 'profile')
						? res.response.data.user.profile.highValueDiner
							? true
							: false
						: null
				);
				identifyEvent.append(
					'traveler',
					hasProp(res.response.data.user, 'profile')
						? res.response.data.user.profile.traveler
							? true
							: false
						: null
				);
				identifyEvent.append(
					'meetingPlanner',
					hasProp(res.response.data.user, 'profile')
						? res.response.data.user.profile.meetingPlanner
							? true
							: false
						: null
				);
				amplitude.identify(identifyEvent);

				// window.analytics &&
				// 	window.analytics.identify(res.response.data.user.id, {
				// 		name: res.response.data.user.name.first + ' ' + res.response.data.user.name.last,
				// 		email: res.response.data.user.email.primary,
				// 		companyName: hasProp(res.response.data.user, 'company')
				// 			? res.response.data.user.company.name
				// 			: null,
				// 		companyCode: hasProp(res.response.data.user, 'company')
				// 			? res.response.data.user.company.code
				// 			: null,
				// 		big_prize_campaign_code: res.response.data.user.bigPrizeCampaignCode ? true : false,
				// 		highValueDiner: hasProp(res.response.data.user, 'profile')
				// 			? res.response.data.user.profile.highValueDiner
				// 				? true
				// 				: false
				// 			: null,
				// 		traveler: hasProp(res.response.data.user, 'profile')
				// 			? res.response.data.user.profile.traveler
				// 				? true
				// 				: false
				// 			: null,
				// 		meetingPlanner: hasProp(res.response.data.user, 'profile')
				// 			? res.response.data.user.profile.meetingPlanner
				// 				? true
				// 				: false
				// 			: null
				// 	});

				let isMyDinovaEligible = true;

				if (doAfterSubmit) {
					doAfterSubmit(isMyDinovaEligible);
				}
			} else {
				window.scrollTo(0, 0);
			}
		},
		[doAfterSubmit]
	);

	const { registerUser, isMutating } = useUserRegistrationMutation({
		onSuccess: res => completeRegistrationStepOne(res)
	});
	const { isDoDMode } = useGeneralContext();

	const initialValues = useMemo(() => {
		let parsed = getLowerCasedParams();

		return {
			companyCode: hasProp(parsed, 'companycode') ? parsed.companycode : '',
			firstName: hasProp(parsed, 'first') ? parsed.first : '',
			lastName: hasProp(parsed, 'last') ? parsed.last : '',
			email: hasProp(parsed, 'wemail') ? parsed.wemail : '',
			campaign: hasProp(parsed, 'utm_campaign') ? parsed.utm_campaign : '',
			registrationPath:
				hasProp(parsed, 'redirect') && parsed.redirect.indexOf('catering') > -1 ? 'catering' : 'mydinova',
			referralToken: hasProp(parsed, 'token') ? parsed.token : '',

			tandc: [],
			username: '',
			password: '',
			monthly_summary: '',
			special_offers: ''
		};
	}, []);

	const onSubmit = useCallback(
		vals => {
			const userData = {
				name: {
					first: vals.firstName,
					last: vals.lastName
				},
				phone: {
					mobile: vals.phone || ''
				},
				email: {
					primary: vals.email
				},
				company: isDoDMode ? 'DOD' : undefined,
				preferences: {
					notification: {
						monthlySummary: vals.monthly_summary,
						specialOffers: vals.special_offers
					}
				},
				userName: vals.username,
				password: vals.password,
				campaign: {
					id: vals.campaign
				},
				registrationPath: vals.registrationPath,
				token: vals.referralToken,

				acceptedTC: true
			};

			registerUser(userData);
		},
		[isDoDMode, registerUser]
	);

	return {
		initialValues,
		isMutating,
		onSubmit
	};
};

export default useRegistrationFormFields;

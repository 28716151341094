import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import useIsProdHost from './useIsProdHost';
import queryString from 'query-string';

const downloadPath = 'download';
const weMovedPath = 'we-moved';
const subscribePath = 'users/register';
export const autoEnrollPath = 'users/register/download';

const useAppDownloadQRCode = () => {
	const location = useLocation();
	const { host, isProdHost } = useIsProdHost();

	const shortLink = queryString.parse(window.location.search).shortlink;

	const { isDownloadPath, isWeMovedPath, isSubscribePath, isAutoEnrollPath } = useMemo(() => {
		const _isAutoEnrollPath = location.pathname.includes(autoEnrollPath);
		return {
			isDownloadPath: location.pathname.includes(downloadPath) && !_isAutoEnrollPath,
			isWeMovedPath: location.pathname.includes(weMovedPath),
			isSubscribePath: location.pathname.includes(subscribePath) && !_isAutoEnrollPath,
			isAutoEnrollPath: _isAutoEnrollPath
		};
	}, [location.pathname]);

	const qrCodeImgSrc = useMemo(() => {
		if (isDownloadPath) {
			return '/download_landing_page_prod_qrCode.png';
		}
		// prod / feature
		else if (isProdHost) {
			if (isWeMovedPath) {
				return '/qrCodes/prod/WeMovedLP_qr_code.png';
			} else if (isSubscribePath) {
				if (shortLink) {
					return `https://dinova.onelink.me/vg2y/${shortLink}`;
				} else {
					return '/qrCodes/prod/RegistrationLP_qr_code.png';
				}
			} else if (isAutoEnrollPath) {
				return '/qrCodes/prod/AutoEnrollLP_qr_code.png';
			}
		}

		// Dev env we-moved, download, subscribe page
		// p4
		if (host.includes('p4')) {
			return '/p4_qrCode.png';
		}

		// uat
		if (host.includes('apps.uat')) {
			return '/uat_qrCode.png';
		}

		// local
		return '/local_qrCode.png';
	}, [host, isDownloadPath, isProdHost, isWeMovedPath, isSubscribePath, isAutoEnrollPath, shortLink]);

	return qrCodeImgSrc;
};

export default useAppDownloadQRCode;

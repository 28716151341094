import React from 'react';
import { Button } from 'components/atoms';
import styles from './styles.module.scss';
import PropTypes from 'prop-types';

/*
interface AppStoreButtonLinkProps {

}
*/

const AppDownloadButtonLink = ({ bgImg, link }) => {
	return (
		<a href={link} target="_blank" rel="noreferrer">
			<Button
				style={{
					background: bgImg
				}}
				className={styles.appStoreButtonLink}
			/>
		</a>
	);
};

AppDownloadButtonLink.propTypes = {
	bgImg: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired
};

export default AppDownloadButtonLink;

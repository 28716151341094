import { useEffect, useRef, useState } from 'react';
import axios from 'axios';

const apiKey = process.env.REACT_APP_QR_CODE_API_KEY;

/* interface useQRCodeProps {
	qrCodeText: string; The URL to be encoded in the QR code
} */

const useQRCode = props => {
	const { frameName = 'no-frame', qrCodeText, qrCodeLogo = 'no-logo' } = props;
	const didLoad = useRef(false);
	const [qrCode, setQRCode] = useState(null);
	const _qrCodeText = qrCodeText.includes('http') ? qrCodeText : `${window.location.origin}${qrCodeText}`;

	useEffect(() => {
		didLoad.current = false;
	}, [props]);

	useEffect(() => {
		if (didLoad.current) {
			return;
		}
		const params = `frame_name=${frameName}&qr_code_text=${_qrCodeText}&image_format=SVG&qr_code_logo=${qrCodeLogo}`;
		const url = `https://api.qr-code-generator.com/v1/create?access-token=${apiKey}&${params}`;

		axios.get(url).then(res => {
			setQRCode(res.data);
			didLoad.current = true;
		});
	}, [frameName, _qrCodeText, qrCodeLogo]);

	return {
		qrCode
	};
};

export default useQRCode;

import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { basePath } from 'actions/utils';
import { useDispatch } from 'react-redux';
import { setError, setIsLoading } from 'actions/loader';
import { hasProp } from 'utils/object';
import * as amplitude from '@amplitude/analytics-browser';

/*
interface useCreateSubscriptionMutationProps {}
*/

const useCreateSubscriptionMutation = ({ onError, onSuccess } = {} /*:useUserMutationProps*/) => {
	const dispatch = useDispatch();

	const mutation = useMutation({
		mutationFn: formData => {
			console.log('%c create subscription mutation:', 'color: yellow', formData);
			return axios.post(`${basePath()}/users`, JSON.stringify(formData));
			// return new Promise(resolve => {
			// console.log('%c create subscription mutation:', 'color: yellow', formData);
			// 	setTimeout(() => {
			// 		resolve({ data: 'success' });
			// 	}, 300);
			// });

			// return await axios.post(`${basePath()}/users/CreateSubscription`, JSON.stringify(formData));
		},
		onMutate: async variables => {
			dispatch(setIsLoading(true, 'Creating User...'));

			return { variables };
		},
		onError: (error /*, variables, context*/) => {
			// An error happened!
			dispatch(setIsLoading(false));

			onError && onError(error);
			dispatch(setError(error.response.status));
		},
		onSuccess: (response /*variables, context*/) => {
			const data = response.data;
			const identifyEvent = new amplitude.Identify();
			amplitude.setUserId(data.user.id);
			identifyEvent.append('name', data.user.name.first + ' ' + data.user.name.last);
			identifyEvent.append('email', data.user.email.primary);
			identifyEvent.append('companyName', hasProp(data.user, 'company') ? data.user.company.name : null);
			identifyEvent.append('companyCode', hasProp(data.user, 'company') ? data.user.company.code : null);
			identifyEvent.append('big_prize_campaign_code', data.user.bigPrizeCampaignCode ? true : false);
			identifyEvent.append(
				'highValueDiner',
				hasProp(data.user, 'profile') ? (data.user.profile.highValueDiner ? true : false) : null
			);
			identifyEvent.append(
				'traveler',
				hasProp(data.user, 'profile') ? (data.user.profile.traveler ? true : false) : null
			);
			identifyEvent.append(
				'meetingPlanner',
				hasProp(data.user, 'profile') ? (data.user.profile.meetingPlanner ? true : false) : null
			);
			amplitude.identify(identifyEvent);

			// window.analytics &&
			// 	window.analytics.identify(data.user.id, {
			// 		name: data.user.name.first + ' ' + data.user.name.last,
			// 		email: data.user.email.primary,
			// 		companyName: hasProp(data.user, 'company') ? data.user.company.name : null,
			// 		companyCode: hasProp(data.user, 'company') ? data.user.company.code : null,
			// 		big_prize_campaign_code: data.user.bigPrizeCampaignCode ? true : false,
			// 		highValueDiner: hasProp(data.user, 'profile')
			// 			? data.user.profile.highValueDiner
			// 				? true
			// 				: false
			// 			: null,
			// 		traveler: hasProp(data.user, 'profile') ? (data.user.profile.traveler ? true : false) : null,
			// 		meetingPlanner: hasProp(data.user, 'profile')
			// 			? data.user.profile.meetingPlanner
			// 				? true
			// 				: false
			// 			: null
			// 	});
			dispatch(setIsLoading(false));

			// onSuccess && onSuccess(data);
			if (response.data.errors) {
				dispatch(setError(response.data.response.code));

				onError && onError(response);
			} else {
				onSuccess && onSuccess(data);
			}
		}
	});

	return {
		isMutating: mutation.isPending,
		isError: mutation.isError,
		isSuccess: mutation.isSuccess,
		reset: mutation.reset,
		createSubscription: mutation.mutate
	};
};

export default useCreateSubscriptionMutation;

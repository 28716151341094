const useIsProdHost = () => {
	const host = window.location.host;
	const prodHosts = [
		'apps.dinova',
		// 'feature'
		// 'dinova.local'
		'p4'
		// 'apps.uat'
	];
	const isProdHost = prodHosts.some(_host => host.includes(_host));

	return { host, isProdHost };
};

export default useIsProdHost;

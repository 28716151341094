import React from 'react';
import { useQRCode } from 'hooks';
import styles from './style.module.scss';
// import PropTypes from 'prop-types'

/*
interface QRCodeProps {

}
*/

const QRCode = (props /*: QRCodeProps*/) => {
	const { qrCode } = useQRCode(props);

	return qrCode ? (
		<div className={styles.qrCode}>
			<img src={`data:image/svg+xml;utf8,${encodeURIComponent(qrCode)}`} />
		</div>
	) : null;
};

// QRCode.propTypes = {

// }

export default QRCode;

import React from 'react';
import { useSelector } from 'react-redux';
import { AppDownloadButtonLink } from 'components/atoms';
import useAppStoreDownloadLink from 'hooks/useAppStoreDownloadLink';

const AppStoreButtonLink = () => {
	const imageBaseUrl = useSelector(state => state.config.imageBaseUrl);
	const downloadLink = useAppStoreDownloadLink();

	return (
		<AppDownloadButtonLink
			bgImg={`url(${imageBaseUrl}/mydinova/logos/apple-app-store-badge.svg) no-repeat`}
			link={downloadLink}
		/>
	);
};

export default AppStoreButtonLink;
